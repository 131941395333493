import React from "react";
import Contador from "./Contador";
import logo from "../../img/logo-publiart (1).webp";


function Footer() {
  return (
    <div>
      <footer className="row mt-5   border-top">
        <div className="col-lg-2">
          <img className="img-fluid mt-5" src={logo} alt="" width="100px" />

          <p className="text-body-secondary">PubliArt.cl © 2023</p>
        </div>
        <div className="col-mb-6 col-lg-6">
          <h2>Contactanos</h2>
          <p>
            Propietario Representante Legal Jeremias Olave C. Telefono
            Movil+56 9 85361391 Whatsapp +56 9 85361391 Dirección Calle Rio
            Teno#1650, Sector Las Quilas, Temuco, Araucania.
          </p>
        </div>
        <div className="col-mb-3 col-lg-3">
          <Contador />
        </div>
      </footer>
    </div>
  );
}

export default Footer;
