import React from "react";
import { Link } from "react-router-dom";

import waza from "../../img/whatsapp.webp";
import logo from "../../img/logo-publiart (1).webp";

const Navbar = () => {
  const phoneNumber = "+56985361391";
  const numero2 = "+56985894619";
  const whatsappURL = `https://wa.me/${phoneNumber}`;
  const whatsappURL2 = `https://wa.me/${numero2}`;

  return (
    <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 border-bottom">
      <div className="col-md-3 mb-2 mb-md-0">
        <span className="fs-4">PubliArt.cl</span>
        <img className="img-fluid" src={logo} alt="" width="50px" />
      </div>

      <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
        <li>
          <Link to="/" className="nav-link px-2 link-danger">
            PubliArt
          </Link>
        </li>
        <li>
          <Link to="/VectorVial" className="nav-link px-2 link-warning">
            Vector Vial
          </Link>
        </li>
      </ul>

      <div className="col-md-3 text-end">
        <a href={whatsappURL} className="nav-link">
          Desea contactarnos{" "}
          <img className="img-fluid" src={waza} alt="" width="50px" />
          {phoneNumber}
        </a>
        <a href={whatsappURL2} className="nav-link">
          {numero2}
        </a>
      </div>
    </header>
  );
};

export default Navbar;
