import React from "react";
import logo from "../../img/logo-vector-vial.webp";
import dem1 from "../../img/dem1.webp";
import dem2 from "../../img/dem2.webp";
import dem3 from "../../img/dem3.webp";
import dem4 from "../../img/dem4.webp";
import dem5 from "../../img/dem5.webp";
import dem6 from "../../img/dem6.webp";
import dem7 from "../../img/dem7.webp";
import tachas1 from "../../img/tachas1.webp";
import tachas2 from "../../img/tachas2.webp";
import tachon1 from "../../img/tachon1.webp";
import tachon2 from "../../img/tachos2.webp";
import valla1 from "../../img/valla1.webp";
import valla2 from "../../img/valla2.webp";
import lomo1 from "../../img/lomo1.webp";
import lomo2 from "../../img/lomo2.webp";
import lomo3 from "../../img/lomo3.webp";
import lomo4 from "../../img/lomo4.webp";
import lomo5 from "../../img/lomo5.webp";
import lomo6 from "../../img/lomo6.webp";
import lomo7 from "../../img/lomo7.webp";
import lomo8 from "../../img/lomo8.webp";
import lomo9 from "../../img/lomo9.webp";

import "../../App.css";

const vectorVial = () => {
  return (
    <div>
      <div>
        <div className="cabeceraVial row d-flex align-items-center justify-content-center contenido">
          <div className="col-md-6 ">
            <img className="img-fluid " src={logo} width="350px" alt="" />
          </div>
          <div className="col-md-5 me-3 oscurecido">
            <h1 className="text text-white">DEMARCACIÓN Y SEÑALIZACIÓN VIAL</h1>
            <h2 className="text text-white">Más de 15 años de experiencia</h2>
            <p className="text text-white">
              Contamos con una gran variedad de servicios de demarcación y
              señalización vial. Especializados en el área, con los mejores
              materiales e insumos del mercado, todo esto, para lograr un
              trabajo de calidad.
            </p>
          </div>
        </div>
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-lg-5">
            <h2>Señalética Vial</h2>
          </div>
          <div className="col-lg-5">
            <h2>Demarcación Vial</h2>
          </div>
        </div>
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-lg-5 carrusel">
            <div
              id="carouselDem1"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselDem1"
                  data-bs-slide-to="0"
                  className="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselDem1"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={dem6}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={dem7}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselDem1"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Anterior</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselDem1"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Siguiente</span>
              </button>
            </div>
          </div>
          <div className="col-lg-5 carrusel">
            <div
              id="carouselDem"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselDem"
                  data-bs-slide-to="0"
                  className="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselDem"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselDem"
                  data-bs-slide-to="2"
                  aria-label="Slide 3"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselDem"
                  data-bs-slide-to="3"
                  aria-label="Slide 4"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselDem"
                  data-bs-slide-to="4"
                  aria-label="Slide 5"
                ></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={dem1}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={dem2}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={dem3}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={dem4}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={dem5}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselDem"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Anterior</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselDem"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Siguiente</span>
              </button>
            </div>
          </div>
        </div>
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-lg-5">
            <h2>Colocación de Tachas</h2>
          </div>
          <div className="col-lg-5">
            <h2>Colocación de Tachones</h2>
          </div>
        </div>
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-lg-5 carrusel">
            <div
              id="carouselTacha"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselTacha"
                  data-bs-slide-to="0"
                  className="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselTacha"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={tachas1}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={tachas2}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselTacha"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Anterior</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselTacha"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Siguiente</span>
              </button>
            </div>
          </div>
          <div className="col-lg-5 carrusel">
            <div
              id="carouselTachon"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselTachon"
                  data-bs-slide-to="0"
                  className="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselTachon"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={tachon1}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={tachon2}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselTachon"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Anterior</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselTachon"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Siguiente</span>
              </button>
            </div>
          </div>
        </div>
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-lg-5">
            <h2>Vallas Peatonales</h2>
          </div>
          <div className="col-lg-5">
            <h2>Lomos de Toro</h2>
          </div>
        </div>
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-lg-5 carrusel">
            <div
              id="carouselVallas"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselVallas"
                  data-bs-slide-to="0"
                  className="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselVallas"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={valla1}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={valla2}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselVallas"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Anterior</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselVallas"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Siguiente</span>
              </button>
            </div>
          </div>
          <div className="col-lg-5 carrusel">
            <div
              id="carouselLomo"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselLomo"
                  data-bs-slide-to="0"
                  className="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselLomo"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselLomo"
                  data-bs-slide-to="2"
                  aria-label="Slide 3"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselLomo"
                  data-bs-slide-to="3"
                  aria-label="Slide 4"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselLomo"
                  data-bs-slide-to="4"
                  aria-label="Slide 5"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselLomo"
                  data-bs-slide-to="5"
                  aria-label="Slide 6"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselLomo"
                  data-bs-slide-to="6"
                  aria-label="Slide 7"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselLomo"
                  data-bs-slide-to="7"
                  aria-label="Slide 8"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselLomo"
                  data-bs-slide-to="8"
                  aria-label="Slide 9"
                ></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={lomo1}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={lomo2}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={lomo3}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={lomo4}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={lomo5}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={lomo6}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={lomo7}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={lomo8}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={lomo9}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselLomo"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Anterior</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselLomo"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Siguiente</span>
              </button>
            </div>
          </div>
        </div>
        <h2>¿Porque contactarnos?</h2>
      </div>
    </div>
  );
};

export default vectorVial;
