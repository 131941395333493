import React from "react";
import { BrowserRouter , Routes, Route } from "react-router-dom";
import NavBar from "./components/navegacion/Navbar";
import Contacto from "./Contacto";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Home from "./components/paginas/Home.js";
import VectorVial from "./components/paginas/VectorVial.js";
import Footer from "./components/navegacion/Footer.js";
import Refer from "./components/paginas/Refer";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <NavBar />

        <Routes>
          <Route path="/"  element={<Home/>} />
          <Route path="/VectorVial"  element={<VectorVial/>} />
        </Routes>
      </BrowserRouter>
      <Refer/>
      <Contacto />
      <Footer/>
    </div>
  );
}

export default App;
