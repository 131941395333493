import React, { useEffect } from "react";

function Contador() {
  useEffect(() => {
    function incrementarContador() {
      localStorage.setItem(
        "contadorVisitas",
        parseInt(localStorage.getItem("contadorVisitas")) + 1
      );
    }

    window.addEventListener("beforeunload", incrementarContador);

    return () => {
      window.removeEventListener("beforeunload", incrementarContador);
    };
  }, []);

  let contador = parseInt(localStorage.getItem("contadorVisitas")) || 0;

  if (contador === 0) {
    contador = 1311;
    localStorage.setItem("contadorVisitas", contador.toString());
  }

  return (
    <div>
      <h2>Contador de Visitas</h2>
      <p>Esta página ha sido visitada {contador} veces.</p>
    </div>
  );
}

export default Contador;
