import React from 'react'
import trayectoria from "../../img/trayectoria-profesional.png";
import seguimiento from "../../img/seguimiento-de-tiempo.png";
import instalacion from "../../img/instalacion.png";
import informacion from "../../img/informacion.png";
import conversacion from "../../img/conversacion.png";
import proteger from "../../img/proteger.png";

function Refer() {
  return (
    <div>
      <h2>¿Porque contactarnos?</h2>
      <div className="row mt-5">
        <div className="col-lg-2 col-md-4 col-sm-6   justify-content-center">
          <img className="img-fluid " src={seguimiento} alt="" />
          <h3>Tiempos de trabajos</h3>
          <p>
            Brindamos tiempos acorde a lo solicitado y entregados en el plazo
            indicado
          </p>
        </div>
        <div className="col-lg-2 col-md-4 col-sm-6   justify-content-center">
          <img className="img-fluid " src={conversacion} alt="" />
          <h3>Comunicación</h3>
          <p>
            Brindar un buen servicio incluye una comunicación constante. Siempre
            estarás al tanto del estado de tu servicio.
          </p>
        </div>
        <div className="col-lg-2 col-md-4 col-sm-6   justify-content-center">
          <img className="img-fluid " src={instalacion} alt="" />
          <h3>Despacho e instalación</h3>
          <p>
            Según el servicio adquirido su pedido será enviado por un medio
            establecido o instalado por nuestro equipo de trabajo.
          </p>
        </div>
        <div className="col-lg-2 col-md-4 col-sm-6   justify-content-center">
          <img className="img-fluid " src={proteger} alt="" />
          <h3>Seguridad</h3>
          <p>
            Nuestro equipo de trabajo utiliza equipos de seguridad para realizar
            las actividades y seguimos las normas de seguridad establecidas en
            Chile.
          </p>
        </div>
        <div className="col-lg-2 col-md-4 col-sm-6   justify-content-center">
          <img className="img-fluid " src={informacion} alt="" />
          <h3>Tecnología</h3>
          <p>
            Para brindar servicios de calidad implementamos las nuevas
            tecnologías para contactarnos y mostrar sus productos.
          </p>
        </div>
        <div className="col-lg-2 col-md-4 col-sm-6   justify-content-center">
          <img className="img-fluid " src={trayectoria} alt="" />
          <h3>Trayectoria</h3>
          <p>
            Publiart es una empresa con 15 años de trayectoria y ciento de
            clientes satisfechos.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Refer