import React from "react";
import logo from "../../img/logo-publiart (1).webp";
import imagen1 from "../../img/pendones1.webp";
import imagen2 from "../../img/pendones2.webp";
import imagen3 from "../../img/pendones3.webp";
import imagen4 from "../../img/pendones4.webp";
import lienzo1 from "../../img/LIENZOS1.webp";
import lienzo2 from "../../img/LIENZOS2.webp";
import lienzo3 from "../../img/LIENZOS3.webp";
import giga1 from "../../img/giga1.webp";
import giga2 from "../../img/giga2.webp";
import giga3 from "../../img/giga3.webp";
import giga4 from "../../img/giga4.webp";
import lum1 from "../../img/lum1.webp";
import lum2 from "../../img/lum2.webp";
import lum3 from "../../img/lum3.webp";
import ban1 from "../../img/ban1.webp";
import ban2 from "../../img/ban2.webp";
import tran1 from "../../img/tran1.webp";
import tran2 from "../../img/tran2.webp";
import tran3 from "../../img/tran3.webp";
import tran4 from "../../img/tran4.webp";
import tran5 from "../../img/tran5.webp";
import tran6 from "../../img/tran6.webp";
import obra1 from "../../img/obra1.webp";
import obra2 from "../../img/obra2.webp";
import ad1 from "../../img/ad1.webp";
import ad2 from "../../img/ads2.webp";
import ad3 from "../../img/ads3.webp";
import ad4 from "../../img/ads4.webp";
import ad5 from "../../img/ads5.webp";
import ad6 from "../../img/ads6.webp";

import '../../App.css'

const home = () => {
  return (
    <div>
      <div>
        <div className="cabecera row d-flex align-items-center justify-content-center contenido">
          <div className="col-md-6 ">
            <img className="img-fluid " src={logo} alt="" />
          </div>
          <div className="col-md-5 me-3 ">
            <h1 className="text text-white">SERVICIOS PUBLICITARIOS</h1>
            <h2 className="text text-white">Más de 15 años de experiencia</h2>
            <p className="text text-white">
              Contamos con una gran variedad de servicios publicitarios con los
              cuales podrá dar mayor valor a su negocio, y lo mas importante,
              captar mas clientes. Especialistas en impresión en todo tipo de
              superficies, con los mejores materiales e insumos del mercado,
              todo esto, para lograr un trabajo de calidad.
            </p>
          </div>
        </div>
        <h2>Pendones</h2>
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-lg-5  table-responsive primario ms-5">
            <table className="table mt-5">
              <thead>
                <tr>
                  <th scope="col">MEDIDAS</th>
                  <th scope="col">TIPO</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>70cm x 150cm</td>
                  <td></td>
                </tr>
                <tr>
                  <td>100cm X 200cm</td>
                  <td></td>
                </tr>
                <tr>
                  <td>200cm X 200cm</td>
                  <td></td>
                </tr>
                <tr>
                  <td>300cm X 300cm</td>
                  <td>FONDOS DE ESCENARIOS</td>
                </tr>
                <tr>
                  <td>80cm x 200cm</td>
                  <td>ROLLER</td>
                </tr>
                <tr>
                  <td>90cm x 200cm</td>
                  <td>ROLLER</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-lg-5 carrusel">
            <div
              id="carouselPendones"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselPendones"
                  data-bs-slide-to="0"
                  className="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselPendones"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselPendones"
                  data-bs-slide-to="2"
                  aria-label="Slide 3"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselPendones"
                  data-bs-slide-to="3"
                  aria-label="Slide 4"
                ></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={imagen1}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={imagen2}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={imagen3}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={imagen4}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselPendones"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Anterior</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselPendones"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Siguiente</span>
              </button>
            </div>
          </div>
        </div>
        <h2>LIENZOS Y PASACALLES</h2>
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-lg-5 carrusel">
            <div
              id="carouselLienzos"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselLienzos"
                  data-bs-slide-to="0"
                  className="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselLienzos"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselLienzos"
                  data-bs-slide-to="2"
                  aria-label="Slide 3"
                ></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={lienzo1}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={lienzo2}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={lienzo3}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselLienzos"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Anterior</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselLienzos"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Siguiente</span>
              </button>
            </div>
          </div>
          <div className="col-lg-5 table-responsive secundario">
            <table className="table mt-5">
              <thead>
                <tr>
                  <th scope="col">MEDIDAS</th>
                  <th scope="col">TIPO</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>100cm x 300cm</td>
                  <td>OJETILLADO PERIMETRAL</td>
                </tr>
                <tr>
                  <td>100cm x 700cm</td>
                  <td>OJETILLADO Y BOLSILLO LATERAL</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <h2>GIGANTOGRAFIAS</h2>
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-lg-5 table-responsive primario ms-5">
            <table className="table mt-5">
              <thead>
                <tr>
                  <th scope="col">MEDIDAS</th>
                  <th scope="col">TIPO</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>12 X 5 Metros</td>
                  <td>
                    LETREROS DE OBRAS GIGANTES Todas instaladas en terreno
                  </td>
                </tr>
                <tr>
                  <td>9 X 4 Metros</td>
                  <td>
                    LETREROS DE OBRAS GIGANTES Todas instaladas en terreno
                  </td>
                </tr>
                <tr>
                  <td>6 X 2,5 Metros</td>
                  <td>
                    LETREROS DE OBRAS GIGANTES Todas instaladas en terreno
                  </td>
                </tr>
                <tr>
                  <td>4 X 2 Metros</td>
                  <td>
                    LETREROS DE OBRAS GIGANTES Todas instaladas en terreno
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-lg-5 carrusel">
            <div
              id="carouselGiga"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselGiga"
                  data-bs-slide-to="0"
                  className="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselGiga"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselGiga"
                  data-bs-slide-to="2"
                  aria-label="Slide 3"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselGiga"
                  data-bs-slide-to="3"
                  aria-label="Slide 4"
                ></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={giga1}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={giga2}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={giga3}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={giga4}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselGiga"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Anterior</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselGiga"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Siguiente</span>
              </button>
            </div>
          </div>
        </div>
        <h2>LETREROS LUMINOSOS</h2>
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-lg-5 carrusel">
            <div
              id="carouselLum"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselLum"
                  data-bs-slide-to="0"
                  className="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselLum"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselLum"
                  data-bs-slide-to="2"
                  aria-label="Slide 3"
                ></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={lum1}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={lum2}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={lum3}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselLum"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Anterior</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselLum"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Siguiente</span>
              </button>
            </div>
          </div>
          <div className="col-lg-5 table-responsive secundario">
            <table className="table mt-5">
              <thead>
                <tr>
                  <th scope="col">
                    Letreros luminosos de distintos tamaños y soportes
                  </th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
        <h2>BANDERAS</h2>
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-lg-5 table-responsive primario ms-5">
            <table className="table mt-5">
              <thead>
                <tr>
                  <th scope="col">
                    Banderas de vela, rectangulares, cuadradas, banderines y
                    más.
                  </th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
          <div className="col-lg-5 carrusel">
            <div
              id="carouselBan"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselBan"
                  data-bs-slide-to="0"
                  className="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselBan"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={ban1}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={ban2}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselBan"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Anterior</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselBan"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Siguiente</span>
              </button>
            </div>
          </div>
        </div>
        <h2>SEÑALETICA DE TRANSITO</h2>
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-lg-5 carrusel">
            <div
              id="carouselTran"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselTran"
                  data-bs-slide-to="0"
                  className="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselTran"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselTran"
                  data-bs-slide-to="2"
                  aria-label="Slide 3"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselTran"
                  data-bs-slide-to="3"
                  aria-label="Slide 4"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselTran"
                  data-bs-slide-to="4"
                  aria-label="Slide 5"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselTran"
                  data-bs-slide-to="5"
                  aria-label="Slide 6"
                ></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={tran1}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={tran2}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={tran3}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={tran4}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={tran5}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={tran6}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselTran"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Anterior</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselTran"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Siguiente</span>
              </button>
            </div>
          </div>
          <div className="col-lg-5 table-responsive secundario">
            <table className="table mt-5">
              <thead>
                <tr>
                  <th scope="col">
                    Todo tipo de señalética de tránsito, excelente calidad
                  </th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
        <h2>SEÑALETICA DE OBRAS</h2>
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-lg-5 table-responsive primario ms-5">
            <table className="table mt-5">
              <thead>
                <tr>
                  <th scope="col">
                    Todo tipo de señalética para todo tipo de obras, aceptamos
                    sus pedidos y los confeccionamos con materiales de excelente
                    calidad.
                  </th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
          <div className="col-lg-5 carrusel">
            <div
              id="carouselObra"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselObra"
                  data-bs-slide-to="0"
                  className="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselObra"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={obra1}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={obra2}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselObra"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Anterior</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselObra"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Siguiente</span>
              </button>
            </div>
          </div>
        </div>
        <h2>ADHESIVOS PUBLICITARIOS</h2>
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-lg-5 carrusel">
            <div
              id="carouselAd"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselAd"
                  data-bs-slide-to="0"
                  className="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselAd"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselAd"
                  data-bs-slide-to="2"
                  aria-label="Slide 3"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselAd"
                  data-bs-slide-to="3"
                  aria-label="Slide 4"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselAd"
                  data-bs-slide-to="4"
                  aria-label="Slide 5"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselAd"
                  data-bs-slide-to="5"
                  aria-label="Slide 6"
                ></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={ad1}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={ad2}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={ad3}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={ad4}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={ad5}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={ad6}
                    className="d-block w-100 img-fluid img-carrusel"
                    alt="..."
                  />
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselAd"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Anterior</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselAd"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Siguiente</span>
              </button>
            </div>
          </div>
          <div className="col-lg-5 table-responsive secundario">
            <table className="table mt-5">
              <thead>
                <tr>
                  <th scope="col">
                    Adhesivos para todos los soportes, cinta, vidrio, metal,
                    etc.
                  </th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default home;
