import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

export default function Contacto() {
  const form = useRef();
  const [contactoEnviado, setContactoEnviado] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_2fc3uxg",
        "template_z7g0qib",
        form.current,
        "P-4fstvYfK0gpJQjG"
      )
      .then(
        (result) => {
          console.log(result.text);
          setContactoEnviado(true);
          form.current.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="col-6">
        <h2>Contáctanos</h2>

        {contactoEnviado ? (
          <p>Mensaje enviado con exito</p>
        ) : (
          <form ref={form} onSubmit={sendEmail}>
            <div className="form-outline mb-4">
              <input
                type="text"
                name="user_name"
                id="form4Example1"
                className="form-control"
              />
              <label className="form-label" htmlFor="form4Example1">
                Nombre
              </label>
            </div>

            <div className="form-outline mb-4">
              <input
                type="text"
                name="telefono"
                id="form4Example1"
                className="form-control"
              />
              <label className="form-label" htmlFor="form4Example1">
                Teléfono
              </label>
            </div>

            <div className="form-outline mb-4">
              <input
                type="email"
                name="correo"
                id="form4Example2"
                className="form-control"
              />
              <label className="form-label" htmlFor="form4Example2">
                Email
              </label>
            </div>

            <div className="form-outline mb-4">
              <textarea
                className="form-control"
                name="message"
                id="form4Example3"
                rows="4"
              ></textarea>
              <label className="form-label" htmlFor="form4Example3">
                Mensaje
              </label>
            </div>

            <input type="submit" value="Enviar" />
          </form>
        )}
      </div>
    </div>
  );
}
